import './App.css';
import React from "react";

let officePhone = "tel:+16144510341";

function App() {
  return (
      <React.Fragment>
          <div>
              <section className="mb-10">
                  <nav className="navbar navbar-expand-lg shadow-md py-2 bg-white relative flex items-center w-full justify-between">
                      <div className="px-6 w-full flex flex-nowrap items-center justify-between">
                          <div className="flex items-center">
                              <a className="navbar-brand text-blue-600" href="/">
                                <img alt="logo" src="/images/bdc_logo_card.webp" width="140" height="46"/>
                              </a>
                              <span className="invisible md:visible ml-2 text-gray-800 font-bold text-xl">Bethel Dental Care</span>
                          </div>

                          <div className="flex items-center lg:ml-auto">
                              <button id="new-patients-form-download" type="button" className="inline-block px-6 py-2.5 mr-2 bg-transparent text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:text-blue-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 transition duration-150 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="light" onClick={() => window.location.href='/forms/bdc_new_patient_packet.pdf'}>New Patient Forms</button>
                          </div>
                      </div>
                  </nav>

                  <div
                      className="relative overflow-hidden bg-no-repeat bg-cover h-72 md:h-[34rem]"
                      style={{
                          "backgroundPosition": "50%",
                          "backgroundImage": "url('/images/1151_1_sm.webp')"
                      }}
                  >
                      <div
                          className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                          style={{
                              "backgroundColor": "rgba(0,0,0,0.65)"
                          }}
                      >
                          <div className="flex justify-center items-center h-full">
                              <div className="text-center text-white px-6 md:px-12">
                                  <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-6">
                                      Bethel Dental Care
                                  </h1>
                                  <h5 className="text-blue-400 mb-12">Dr. Sarah Zarick, DDS</h5>
                                  <button
                                      id="call-hero"
                                      type="button"
                                      className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded hover:bg-blue-700 focus:ring-0 transition duration-150 ease-in-out"
                                      data-mdb-ripple="true"
                                      data-mdb-ripple-color="light"
                                      onClick={() => window.location.href=officePhone}
                                  >
                                      Call Us Today!<br/>
                                      (614) 451-0341
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="px-6 py-6 lg:my-12 md:px-12 text-gray-800 text-center lg:text-left">
                      <div className="container mx-auto xl:px-32">
                          <div className="grid lg:grid-cols-1 gap-12 flex items-center">
                              <div className="mt-0 lg:mt-0">
                                  <div className="mt-2">
                                      <a className="underline font-bold" href="#location">Office Location</a> | <a className="underline font-bold" href="#payment">Payment Information</a>
                                  </div>
                                  <div className="mt-4">
                                      Dr. Zarick and staff provide caring general dentistry for patients of all ages. Our services include exams, X-rays, cleanings, restorations (fillings), some extractions, some root canals, tooth whitening, crowns, bridges, dentures and partial dentures.
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>

              <section className="mb-10" id="payment">
                  <div
                      className="relative overflow-hidden bg-no-repeat bg-cover"
                      style={{
                          "backgroundPosition": "50%",
                          "backgroundImage": "url('/images/insurance-logos.webp')",
                          "height": "500px"
                    }}
                  >
                      <div
                          className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                          style={{
                                "backgroundColor": "rgba(0,0,0,0.75)"
                              }}
                      >
                          <div className="flex justify-center items-center h-full">
                              <div className="text-center text-white px-6 md:px-12">
                                  <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-6">
                                      We accept most insurance plans
                                  </h1>
                                  <h5 className="text-blue-400 mb-12">Bethel Dental Care accepts more insurance plans than most offices in the area</h5>
                                  <button
                                      id="call-payment"
                                      type="button"
                                      className="inline-block px-7 py-3 border-2 border-white text-white font-medium text-sm leading-snug uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                      data-mdb-ripple="true"
                                      data-mdb-ripple-color="light"
                                      onClick={() => window.location.href=officePhone}
                                  >
                                      Call Us Today!
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="px-6 py-12 lg:my-12 md:px-12 text-gray-800 text-center lg:text-left">
                      <div className="container mx-auto xl:px-32">
                          <div className="grid lg:grid-cols-2 gap-12 flex">
                          <div>
                              <h2 className="text-3xl font-bold mb-6">
                                  Payment Methods<br/>
                              </h2>
                              <ul>
                                  <li>Cash</li>
                                  <li>Checks</li>
                                  <li>Credit Cards (MasterCard, Visa, Discover)</li>
                                  <li>CareCredit - <a className="underline font-bold" target="_blank" rel="noreferrer" href="https://www.carecredit.com/doctor-locator/results/Bethel-Dental-Care/?">Apply</a></li>
                                  <li>And Most Dental insurance plans</li>
                              </ul>
                          </div>
                          <div>
                          <h2 className="text-3xl font-bold mb-6">
                              Accepted Dental Insurance Plans<br/>
                          </h2>
                              <div className="grid lg:grid-cols-2 gap-12 flex">
                                  <ul>
                                      <li>Aetna PPO</li>
                                      <li>Ameritas PPO</li>
                                      <li>Anthem PPO (not Anthem HMO Medicare)</li>
                                      <li>Brokers National Life</li>
                                      <li>Caresource</li>
                                      <li>Cigna PPO</li>
                                      <li>Companion Life</li>
                                      <li>Connections Dental/GEHA</li>
                                      <li>Coresource</li>
                                      <li>Dearborn National</li>
                                      <li>Delta Dental PPO</li>
                                      <li>Delta Dental Premier</li>
                                      <li>Delta Dental Patient Direct</li>
                                      <li>Dental Care Plus Group/Dental Select Plus</li>
                                  </ul>
                                  <ul className="-mt-12 md:mt-0">
                                      <li>Freedom Life Insurance Company</li>
                                      <li>Guardian PPO</li>
                                      <li>Humana PPO</li>
                                      <li>Lincoln Financial</li>
                                      <li>Medical Mutual</li>
                                      <li>Metlife</li>
                                      <li>Principle</li>
                                      <li>Prudential</li>
                                      <li>Superior Dental Care</li>
                                      <li>Tricare</li>
                                      <li>Unicare</li>
                                      <li>United Concordia</li>
                                      <li>United Health Care PPO</li>
                                  </ul>
                              </div>

                          </div>
                      </div>
                      </div>
                  </div>
              </section>

              <section className="mb-10" id="location">
                  <div
                      className="relative overflow-hidden bg-no-repeat bg-cover"
                      style={{
                          "backgroundPosition": "50%",
                          "backgroundImage": "url('/images/map.webp')",
                          "height": "500px"
                      }}
                  >
                      <div
                          className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                          style={{
                              "backgroundColor": "rgba(0,0,0,0.75)"
                          }}
                      >
                          <div className="flex justify-center items-center h-full">
                              <div className="text-center text-white px-6 md:px-12">
                                  <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12">
                                      Conveniently Located Just Off 315 on Bethel Road
                                  </h1>
                                  <button
                                      id="call-location"
                                      type="button"
                                      className="inline-block px-7 py-3 border-2 border-white text-white font-medium text-sm leading-snug uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                      data-mdb-ripple="true"
                                      data-mdb-ripple-color="light"
                                      onClick={() => window.location.href=officePhone}
                                  >
                                      Call Us Today!
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="px-6 py-12 lg:my-12 md:px-12 text-gray-800 text-center lg:text-left">
                      <div className="container mx-auto xl:px-32">
                          <div className="grid lg:grid-cols-2 gap-12 flex items-center">
                              <div className="mb-2 lg:mb-0 mx-auto">
                                  <img
                                      src="/images/entry_sm.webp"
                                      className="w-full rounded-lg shadow-lg w-[34rem]"
                                      alt=""
                                  />
                              </div>
                              <div className="mt-0 lg:mt-0">
                                  <div className="mt-0">
                                      <span>Bethel Dental Care is conveniently located on Bethel Road just west of the
                                          railway bridge in the Bethel Grove Offices.<br/><br/>Additional parking is available in
                                          the rear of the building.</span>
                                      <br/>
                                      <br/>
                                      <span className="font-bold">
                                      1151 Bethel Road<br/>
                                      Suite 203<br/>
                                      Columbus, OH 43220<br/>
                                      Bethel Grove Offices (turn left once you turn into the parking lot)<br/>
                                      </span>
                                      <br/>
                                      <a className="underline" target="_blank" rel="noreferrer" href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=Bethel+Dental+Care,Sarah+Zarick,+Columbus,+OH&amp;aq=1&amp;oq=Bethel+Dental+Care&amp;sll=37.0625,-95.677068&amp;sspn=42.224734,79.013672&amp;t=m&amp;ie=UTF8&amp;hq=Bethel+Dental+Care&amp;hnear=Columbus,+Franklin,+Ohio&amp;cid=7591230548572380939&amp;ll=40.073343,-83.048229&amp;spn=0.022988,0.036478&amp;z=14&amp;iwloc=A">View Map</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>

              <section className="mb-20 text-gray-800 text-center md:text-left" id="about">
                  <div
                      className="relative overflow-hidden bg-no-repeat bg-cover"
                      style={{
                          "backgroundPosition": "50%",
                          "backgroundImage": "url('/images/assoc_logos.webp')",
                          "height": "500px"
                      }}
                  >
                      <div
                          className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                          style={{
                              "backgroundColor": "rgba(0,0,0,0.75)"
                          }}
                      >
                          <div className="flex justify-center items-center h-full">
                              <div className="text-center text-white px-6 md:px-12">
                                  <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12">
                                      About Dr. Zarick
                                  </h1>
                                  <button
                                      id="call-about"
                                      type="button"
                                      className="inline-block px-7 py-3 border-2 border-white text-white font-medium text-sm leading-snug uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                      data-mdb-ripple="true"
                                      data-mdb-ripple-color="light"
                                      onClick={() => window.location.href=officePhone}
                                  >
                                      Call Us Today!
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="px-6 py-12 lg:my-12 md:px-12 text-gray-800 text-center lg:text-left">
                      <div className="container mx-auto xl:px-32">

                              <div className="mb-6 md:mb-0">
                                  <h2 className="text-3xl font-bold mb-6">
                                      About Dr. Zarick<br/>
                                  </h2>
                                  <p className="text-gray-500 mb-6">
                                      Dr. Zarick earned both her Bachelor of Science and Doctor of Dental Surgery Degrees from
                                      The Ohio State University. The majority of her professional experience comes from being a
                                      general dentist in a local group practice. She has also worked on a mobile basis in
                                      nursing homes and in elementary schools. Dr. Zarick's most unique dental experience was
                                      being a volunteer for the Indian Health Service. She went to Pine Ridge, South Dakota and
                                      provided dental services for Lakota tribe people for two weeks.
                                  </p>

                                  <p className="text-gray-500 mb-6">
                                      Dr. Zarick is a member of The American Dental Association, The Ohio Dental Association
                                      and The Columbus Dental Society. When not in the office, Dr. Zarick is fond of outdoor
                                      activities and youth sports with her family.
                                  </p>

                              </div>



                      </div>
                  </div>
              </section>

              <footer className="bg-gray-200 text-center lg:text-left">
                  <div className="text-gray-700 text-center p-4" style={{"backgroundColor": "rgba(0, 0, 0, 0.2)"}}>
                      © {new Date().getFullYear()} Bethel Dental Care
                      <br/>
                      Build: {process.env.REACT_APP_AWS_JOB_ID}
                  </div>
              </footer>
          </div>
      </React.Fragment>
  );
}

export default App;
